import React, { useState, useEffect } from 'react';
import { backendDomain } from '../constants';
import FavoriteIcon from '@mui/icons-material/Favorite';
import './css/UserOverview.css';

const UserOverview = ({ user }) => {
    const { firstName, lastName, imageUri, likes, visitors, emailId } = user;

    const [stats, setStats] = useState({
        played: 0,
        winPercentage: 0,
        currentStreak: 0,
        maxStreak: 0,
    });

    useEffect(() => {
        // Fetch stats from the APIs
        const fetchStats = async () => {
            try {
                // Fetch game completion stats
                const response1 = await fetch(`${backendDomain}/wordle/get-wordle-completion-stats-for-user`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ emailId }),
                });
                const data1 = await response1.json();
                if (data1.status === 200) {
                    // Fetch user streak information
                    const response2 = await fetch(`${backendDomain}/wordle/get-user-streak-information`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ emailId }),
                    });
                    const data2 = await response2.json();
                    if (data2.status === 200) {
                        setStats({
                            played: data1.played,
                            winPercentage: data1.winPercentage,
                            currentStreak: data2.currentStreak,
                            maxStreak: data2.maxStreak,
                        });
                    } else {
                        console.error('Failed to fetch streak information');
                    }
                } else {
                    console.error('Failed to fetch completion stats');
                }
            } catch (error) {
                console.error('Error fetching stats:', error);
            }
        };

        fetchStats();
    }, [emailId, imageUri]);

    return (
        <div className="container">
            <img src={imageUri} alt={`${firstName} ${lastName}`} className="image" />
            <div className="name">{`${firstName} ${lastName}`}</div>
            <div className="stats">
                <div className="stat">
                    <span role="img" aria-label="visits" className="icon">
                        🔥
                    </span>
                    {stats.currentStreak}
                </div>
                <div className="separator">|</div>
                <div className="stat">
                    <FavoriteIcon
                        className="heart-icon liked"
                        style={{ height: '20px', width: '18px' }}
                    />
                    {likes}
                </div>
            </div>
            <div className="additional-stats">
                <div className="stat">
                    <div className="stat-number">{stats.played}<br />
                        <span className="stat-subtext">Games</span>
                    </div>
                    <div className="stat-text">Played</div>
                </div>
                <div className="stat">
                    <div className="stat-number">{Math.ceil(stats.winPercentage)}%<br /> {/* Rounded up */}
                    <span className="stat-subtext">Win</span>
                    </div>
                    <div className="stat-text">Ratio</div>
                </div>
                <div className="stat">
                    <div className="stat-number">{stats.currentStreak}<br />
                        <span className="stat-subtext">Current</span>
                    </div>
                    <div className="stat-text">Streak</div>
                </div>
                <div className="stat">
                    <div className="stat-number">
                        {stats.maxStreak}<br />
                        <span className="stat-subtext">Max</span>
                    </div>
                    <div className="stat-text">Streak</div>
                </div>
            </div>
        </div>
    );
};

export default UserOverview;

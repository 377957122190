import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import './css/Navbar.css'; // Add your styles here

const Navbar = () => {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(location.pathname);
    const user = useSelector((state) => state.user);

    const handleClick = (path) => {
        if (location.pathname === path) {
            // Force page reload if already on the same page
            window.location.reload();
        } else {
            setActiveTab(path);
        }
    };

    return (
        <div className="navbar">
            <div className="navbar-content">
                <Link
                    to="/"
                    className={`navbar-item ${activeTab === '/' ? 'active' : ''}`}
                    onClick={() => handleClick('/')}
                >
                    <HomeIcon className="icon" />
                    <span className={`label ${activeTab === '/' ? 'active' : ''}`}>Feed</span>
                </Link>
                <Link
                    to="/wordle"
                    className={`navbar-item ${activeTab === '/wordle' ? 'active' : ''}`}
                    onClick={() => handleClick('/wordle')}
                >
                    <img src='/assets/wordle-icon-256-removebg-preview.png' alt="Wordle" className="wordle-icon" />
                    <span className={`label ${activeTab === '/wordle' ? 'active' : ''}`}>Wordle</span>
                </Link>
                <Link
                    to="/profile"
                    className={`navbar-item ${activeTab === '/profile' ? 'active' : ''}`}
                    onClick={() => handleClick('/profile')}
                >
                    {user.imageUri ? (
                        <img src={user.imageUri} alt="Profile" className="profile-icon" />
                    ) : (
                        <AccountCircleIcon className="icon" />
                    )}
                    <span className={`label ${activeTab === '/profile' ? 'active' : ''}`}>Profile</span>
                </Link>
            </div>
        </div>
    );
};

export default Navbar;

import React, { useEffect, useState } from 'react';
import { keyboardDomain } from '../constants';
import BackspaceIcon from '@mui/icons-material/Backspace'; // Importing Material IO icons
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { keys } from '../data/letters';
import './css/Keypad.css'

export default function Keypad({ usedKeys, handleKeyup, isCorrect }) {
  const [letters, setLetters] = useState(keys);

  const handleButtonClick = (key) => {
    if (!isCorrect) { // Disable if the answer is correct
      handleKeyup({ key });
    }
  };

  return (
    <div className="keypad">
      {letters && letters.map(l => {
        const color = usedKeys[l.key.toLowerCase()];
        return (
          <div
            key={l.key}
            className={`key ${color}`}
            onClick={() => handleButtonClick(l.key.toLowerCase())} // Add onClick handler
          >
            {l.key}
          </div>
        );
      })}
      <div className="key delete-key" onClick={() => handleButtonClick('Backspace')}>
        <BackspaceIcon />
      </div>
      <div className="key enter-key" onClick={() => handleButtonClick('Enter')}>
        <KeyboardReturnIcon />
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './css/WordleOverlayComponent.css'; // Import the CSS file

const WordleOverlayComponent = ({ user }) => {
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(true);

    const handlePlayClick = () => {
        setIsVisible(false);
    };

    const handleLoginClick = () => {
        navigate('/profile');
    };

    if (!isVisible) return null;

    return (
        <div className="overlay">
            <div className="container">
                <div className="icon">
                    <div className="grid">
                        {[...Array(9)].map((_, i) => (
                            <div key={i} className={`grid-cell ${i >= 3 && i <= 5 ? 'green-cell' : ''}`}></div>
                        ))}
                    </div>
                </div>
                <h1 className="title">Wordle</h1>
                <p className="description">Get 6 chances to guess a 5-letter word.</p>
                {user?.emailId && (
                    <button className="play-button" onClick={handlePlayClick}>
                        Play
                    </button>)}
                {!user?.emailId && (
                    <button className="login-button" onClick={handleLoginClick}>
                        Login and Play
                    </button>
                )}
            </div>
        </div>
    );
};

export default WordleOverlayComponent;

import React, { useEffect, useState } from 'react';
import useWordle from '../hooks/useWordle';
import Grid from './Grid';
import Keypad from './Keypad';
import Modal from './Modal';

export default function Wordle({ solution, existingGuesses, gameId, user, message }) {
  const [initialGuesses, setInitialGuesses] = useState(existingGuesses);
  const { currentGuess, guesses, turn, isCorrect, usedKeys, handleKeyup } = useWordle(solution, initialGuesses, gameId, user, message);
  const [showModal, setShowModal] = useState(false);
  const [modalShown, setModalShown] = useState(false); // Track if the modal has already been shown

  useEffect(() => {
    setInitialGuesses(existingGuesses);
  }, [existingGuesses]);

  useEffect(() => {
    window.addEventListener('keyup', handleKeyup);
    if (isCorrect || turn > 5)
      window.removeEventListener('keyup', handleKeyup);

    if ((isCorrect || turn > 5) && !modalShown) {
      setModalShown(true); // Mark the modal as shown
      setTimeout(() => setShowModal(true), 2000);
    }

    return () => window.removeEventListener('keyup', handleKeyup);
  }, [handleKeyup, isCorrect, turn, modalShown]);

  const handleCloseModal = () => {
    setShowModal(false);
    if (!gameId)
      window.location.reload();
  };

  return (
    <div>
      <Grid guesses={guesses} currentGuess={currentGuess} turn={turn} />
      <Keypad usedKeys={usedKeys} handleKeyup={handleKeyup} isCorrect={isCorrect} />
      {showModal && <Modal isCorrect={isCorrect} turn={turn} solution={solution.toUpperCase()} onClose={handleCloseModal} id={gameId} message={message} />}
    </div>
  );
}

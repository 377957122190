import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { backendDomain } from '../constants';

// Async thunk to save user data to backend and update the Redux store
export const saveUser = createAsyncThunk(
    'user/saveUser',
    async (userData, { rejectWithValue, fulfillWithValue }) => {
        try {
            // If userData is null or undefined, fulfill with null directly
            if (!userData) {
                return fulfillWithValue(null);
            }

            const response = await fetch(`${backendDomain}/user/login-user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
            });

            const data = await response.json();

            if (data.status !== 200) {
                throw new Error(`Failed to save user: ${data.status}`);
            }

            return data.user; // Assuming the `user` object is in the response
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


export const userSlice = createSlice({
    name: 'user',
    initialState: {
        isAdmin: undefined,
        firstName: undefined,
        lastName: undefined,
        emailId: undefined,
        imageUri: undefined,
        likes: undefined,
        visitors: undefined,
        status: 'idle', // for handling the status of the async call
        error: null, // for storing error messages
    },
    reducers: {
        clearUser: (state) => {
            state.firstName = undefined;
            state.lastName = undefined;
            state.emailId = undefined;
            state.imageUri = undefined;
            state.likes = undefined;
            state.visitors = undefined;
            state.status = 'idle';
            state.error = null;
            state.isAdmin = undefined;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(saveUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(saveUser.fulfilled, (state, action) => {
                console.log("Payload : ", action.payload)
                if (action.payload === null) {
                    // If userData was null, directly set status to succeeded
                    state.status = 'succeeded';
                } else {
                    // If we have user data, update the state
                    const user = action.payload;
                    state.isAdmin = user.isAdmin;
                    state.firstName = user.firstName;
                    state.lastName = user.lastName;
                    state.emailId = user.email;
                    state.imageUri = user.imageUri;
                    state.likes = user.likes;
                    state.visitors = user.visitors;
                    state.status = 'succeeded';
                }
            })
            .addCase(saveUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});


export const { clearUser } = userSlice.actions;
export default userSlice.reducer;

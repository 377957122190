/**
 * Custom hook for managing the Wordle game state.
 * 
 * @param {string} solution - The correct word to guess.
 * @param {Array<string>} existingGuesses - Previous guesses made by the user.
 * @param {string} gameId - Unique identifier for the game.
 * @param {Object} user - User information, including emailId.
 * @param {string} message - Additional message to send with the game update.
 * 
 * @returns {Object} - Contains game state and functions to handle user input.
 */

import { useState, useEffect } from 'react';
import { backendDomain } from '../constants';

const useWordle = (solution, existingGuesses = [], gameId, user, message) => {
  // Function to format existing guesses for display
  const formatExistingGuess = (guess = '', solution) => {
    if (!guess) return null;
    let solutionArray = [...solution];
    return [...guess].map((l, i) => {
      let color = 'grey';
      if (solution[i] === l) {
        color = 'green';
        solutionArray[i] = null;
      } else if (solutionArray.includes(l)) {
        color = 'yellow';
        solutionArray[solutionArray.indexOf(l)] = null;
      }
      return { key: l, color };
    });
  };

  console.log("Existing guesses in hook:", existingGuesses);
  const [turn, setTurn] = useState(existingGuesses.length);
  const [currentGuess, setCurrentGuess] = useState('');
  const [guesses, setGuesses] = useState([...Array(6)].map((_, i) => formatExistingGuess(existingGuesses[i], solution)));
  const [history, setHistory] = useState(existingGuesses);
  const [isCorrect, setIsCorrect] = useState(existingGuesses[existingGuesses.length - 1] === solution);
  const [usedKeys, setUsedKeys] = useState({});

  // Update the guesses, history, and turn based on existingGuesses and solution
  useEffect(() => {
    setGuesses([...Array(6)].map((_, i) => formatExistingGuess(existingGuesses[i], solution)));
    setHistory(existingGuesses);
    setTurn(existingGuesses.length);
  }, [existingGuesses, solution]);

  useEffect(() => {
    setIsCorrect(existingGuesses.length > 0 ? existingGuesses[existingGuesses.length - 1] === solution : false)
  }, [existingGuesses])

  // Function to format the current guess for display
  const formatGuess = () => {
    let solutionArray = [...solution];
    let formattedGuess = [...currentGuess].map((l) => {
      return { key: l, color: 'grey' };
    });

    formattedGuess.forEach((l, i) => {
      if (solution[i] === l.key) {
        formattedGuess[i].color = 'green';
        solutionArray[i] = null;
      }
    });

    formattedGuess.forEach((l, i) => {
      if (solutionArray.includes(l.key) && l.color !== 'green') {
        formattedGuess[i].color = 'yellow';
        solutionArray[solutionArray.indexOf(l.key)] = null;
      }
    });

    return formattedGuess;
  };

    // Function to calculate the streak for a user
  const calculateStreak = async (emailId) => {
    try {
      const response = await fetch(`${backendDomain}/wordle/get-user-streak-information`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ emailId }),
      });
      const data = await response.json();
      if (data.status === 200) {
        return [data.currentStreak, data.maxStreak];
      } else {
          console.error('Failed to fetch streak information after completing a game');
      }
    } catch (error) {
      console.error('Error calculating streak:', error);
      return [0, 0];
    }
  };

  // Function to update the max streak
  const updateMaxStreak = async (emailId, maxStreak) => {
    try {
      console.log("Updating max streak:", emailId, maxStreak);
      
      const reqBody = { emailId: emailId, maxStreak: maxStreak }
      const response = await fetch(`${backendDomain}/user/update-max-streak`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(reqBody),    
        
      });
      const data = await response.json();
      if (data.status === 200) {
        return data.maxStreak;
      } else {
        console.error('Failed to update max streak:', data);
      }
    } catch (error) {
      console.error('Error updating max streak:', error);
    }
  };

  // Function to add a new guess to the game state
  const addNewGuess = async (formattedGuess) => {
    if (currentGuess === solution) 
      {
        setIsCorrect(true);

        // Calculate the new streak
        const [newStreak, maxStreak] = await calculateStreak(user.emailId);

        // Update the WordleGame with the new streak
        if (user.emailId && gameId) {
        updateWordleGameForUser(gameId, {
          email: user.emailId,
          solution,
          guesses: [...history, currentGuess],
          message: message,
          streak: newStreak + 1
        });
        if (newStreak + 1 > maxStreak) {
          updateMaxStreak(user.emailId, newStreak + 1);
        }
      }
    } else {
      // If the guess is incorrect, reset the streak to 0
      if (user.emailId && gameId) {
        updateWordleGameForUser(gameId, {
          email: user.emailId,
          solution,
          guesses: [...history, currentGuess],
          message: message,
          streak: 0 // Keep streak as 0 for incorrect guesses
        });
      }
    }
    setGuesses((prevGuesses) => {
      let newGuesses = [...prevGuesses];
      newGuesses[turn] = formattedGuess;
      return newGuesses;
    });

    setHistory((prevHistory) => [...prevHistory, currentGuess]);
    setTurn((prevTurn) => prevTurn + 1);

    // Update usedKeys on keyboard. Delay is set in index.css
    setUsedKeys((prevUsedKeys) => {
      formattedGuess.forEach((l) => {
        const currentColor = prevUsedKeys[l.key];
        if (l.color === 'green') {
          prevUsedKeys[l.key] = 'green';
          return;
        }
        if (l.color === 'yellow' && currentColor !== 'green') {
          prevUsedKeys[l.key] = 'yellow';
          return;
        }
        if (l.color === 'grey' && currentColor !== ('green' || 'yellow')) {
          prevUsedKeys[l.key] = 'grey';
          return;
        }
      });
      return prevUsedKeys;
    });

    setCurrentGuess('');
  };



  // Handle keyboard input for user guesses
  const handleKeyup = ({ key }) => {
    if (key === 'Enter') {
      if (turn > 5) {
        console.log('you used all your guesses!');
        return;
      }
      if (history.includes(currentGuess)) {
        alert('You already tried that word.');
        return;
      }
      if (currentGuess.length !== 5) {
        alert('Too short.');
        return;
      }
      validateGuess(currentGuess).then(isValid => {
        console.log("Is guess valid:", isValid);
        if (!isValid) {
          alert('Invalid word!');
          return;
        }
        const formatted = formatGuess();
        addNewGuess(formatted);
      }).catch(error => {
        console.error("Error during guess validation:", error);
        alert('Error validating word. Please try again.');
      });
    }
    if (key === 'Backspace') {
      setCurrentGuess((prev) => prev.slice(0, -1));
      return;
    }
    if (/^[A-Za-z]$/.test(key)) {
      if (currentGuess.length < 5) {
        setCurrentGuess((prev) => prev + key);
      }
    }
  };

  // Function to update the game state on the server
  const updateWordleGameForUser = async (id, wordle) => {
    try {
      console.log("Updating the game details:", id, wordle);
      const response = await fetch(`${backendDomain}/wordle/update-wordles-game-for-existing-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, wordle }),
      });
      const data = await response.json();
      if (response.status !== 200) {
        throw new Error(`Failed to update Wordle game: ${data.message}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return { turn, currentGuess, guesses, isCorrect, usedKeys, handleKeyup };
};

const validateGuess = async (guess) => {
  try {
    const response = await fetch(`${backendDomain}/wordle/validate-guess`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ guess: guess.toLowerCase() }),
    });
    const data = await response.json();
    console.log("Backend response:", response.status, data);
    if (response.status !== 200) {
      throw new Error(`Failed to validate guess: ${data.message}`);
    }
    return data.validGuess;
  } catch (error) {
    console.error("Error validating guess:", error);
    return false; // Return false on error to prevent undefined behavior
  }
}

export default useWordle;

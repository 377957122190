import React, { useEffect } from 'react'
import Header from '../components/Header'
import FeedComponent from '../components/FeedComponent'
import { useDispatch, useSelector } from 'react-redux'
import { saveUser } from '../reducers/user'

const Feed = () => {

    const user = useSelector((state) => state.user);
    const dispatch = useDispatch()

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser) {
            console.log("Already logged in")
            dispatch(saveUser(storedUser));
        }
    }, [dispatch]);


    return (
        <>
            <Header />
            <FeedComponent user={user} />
        </>
    )
}

export default Feed
/**
 * PostOverview component displays a single post with user information, 
 * likes functionality, and the Wordle game grid.
 * 
 * Props:
 * - user: Object containing user information (e.g., name, imageUri).
 * - wordle: Object containing Wordle game data (e.g., message, likers).
 */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { backendDomain } from "../constants";
import { useSelector, useDispatch } from "react-redux";
import LikesOverview from "./LikesOverview";
import { saveUser } from "../reducers/user";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import DeleteIcon from '@mui/icons-material/Delete';
import './css/PostOverview.css';

const PostOverview = ({ user, wordle }) => {
    // State variables for managing likes, streak, visibility of likes overview, like count, and delete dialog
    const [liked, setLiked] = useState(false);
    const [showLikesOverview, setShowLikesOverview] = useState(false);
    const [likeCount, setLikeCount] = useState(wordle.likers.length);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const navigate = useNavigate();
    const loggedInUser = useSelector((state) => state.user);
    const dispatch = useDispatch();

    // Effect to load user's streak information from the backend
    useEffect(() => {
        if (wordle.streak === 0) {
            async function loadStreakInfo() {
                const response = await fetch(`${backendDomain}/wordle/get-user-streak-information`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ emailId: wordle.email }),
                });
                const data = await response.json();
                if (data.status === 200) {
                    // Update the wordle object with the fetched streak
                    wordle.streak = data.currentStreak; // Update the streak in the wordle object
                } else {
                    console.error('Failed to fetch streak information');
                }
            }
            loadStreakInfo();
        } else {
            console.log("Streak already present for post: ", wordle._id, " with streak: ", wordle.streak, " and email: ", wordle.email)
        }
    }, [wordle]);

    // Effect to check if a user is already logged in and dispatch user data
    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser) {
            console.log("Already logged in");
            dispatch(saveUser(storedUser));
        }
    }, [dispatch]);

    // Effect to set the liked state based on whether the logged-in user has liked the post
    useEffect(() => {
        if (loggedInUser)
            setLiked(wordle.likers.includes(loggedInUser.emailId));
    }, []);

    // Function to handle like button click
    const handleLikeClick = async () => {
        if (!loggedInUser.emailId) return;

        setLiked(!liked);
        const reqBody = { emailId: wordle.email, delta: 1 }
        if (wordle.likers.includes(loggedInUser.emailId))
            reqBody.delta = -1
        await fetch(`${backendDomain}/user/update-likes`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(reqBody),
        });

        if (wordle.likers.includes(loggedInUser.emailId))
            wordle.likers = wordle.likers.filter(liker => liker !== loggedInUser.emailId)
        else
            wordle.likers.push(loggedInUser.emailId);
        setLikeCount(wordle.likers.length)
        console.log(wordle)
        await fetch(`${backendDomain}/wordle/update-wordles-game-for-existing-user`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ id: wordle._id, wordle: wordle }),
        });
    };

    // Function to navigate to the user's profile
    const handleNameClick = () => {
        navigate(`/profile/${user._id}`);
    };

    // Function to show likes overview
    const handleLikesNumberClick = () => {
        setShowLikesOverview(true);
    };

    // Function to close the likes overview
    const closeLikesOverview = () => {
        setShowLikesOverview(false);
    };

    // Function to generate the Wordle grid based on guesses and solution
    const generateGrid = () => {
        const { guesses, solution } = wordle;
        const solutionLetterCount = {};

        [...solution].forEach(letter => {
            solutionLetterCount[letter] = (solutionLetterCount[letter] || 0) + 1;
        });

        return guesses.map((guess, rowIndex) => {
            const row = [];
            const letterUsedCount = {};

            [...guess].forEach((letter, index) => {
                if (letter === solution[index]) {
                    row.push("green");
                    letterUsedCount[letter] = (letterUsedCount[letter] || 0) + 1;
                } else {
                    row.push(null);
                }
            });

            [...guess].forEach((letter, index) => {
                if (row[index]) return;

                if (solution.includes(letter) && (letterUsedCount[letter] || 0) < (solutionLetterCount[letter] || 0)) {
                    row[index] = "yellow";
                    letterUsedCount[letter] = (letterUsedCount[letter] || 0) + 1;
                } else {
                    row[index] = "grey";
                }
            });

            return row;
        });
    };

    // Function to handle canceling the delete dialog
    const handleDeleteCancel = () => {
        setShowDeleteDialog(false);
    };

    // Function to toggle the delete confirmation dialog
    const handleDeleteClick = () => {
        setShowDeleteDialog(!showDeleteDialog);
    };

    // Function to confirm deletion of the post
    const handleDeleteConfirm = async () => {
        setShowDeleteDialog(false);
        await fetch(`${backendDomain}/wordle/update-wordles-game-for-existing-user`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ id: wordle._id, wordle: { isDeleted: true } }),
        });
        window.location.reload();
        // Optionally, you can navigate away or refresh the list after deletion
    };

    // Function to calculate the time difference from the post creation date
    const calculateTimeDifference = (createdAt) => {
        const now = new Date();
        const created = new Date(createdAt);
        const diffInSeconds = Math.floor((now - created) / 1000);

        const intervals = [
            { label: 'y', seconds: 31536000 },
            { label: 'mo', seconds: 2592000 },
            { label: 'd', seconds: 86400 },
            { label: 'h', seconds: 3600 },
            { label: 'm', seconds: 60 },
            { label: 's', seconds: 1 }
        ];

        for (let i = 0; i < intervals.length; i++) {
            const interval = intervals[i];
            const count = Math.floor(diffInSeconds / interval.seconds);
            if (count >= 1) {
                return `${count}${interval.label}`;
            }
        }

        return '0s';
    };

    // Generate the grid and time display for the post
    const grid = generateGrid();
    const timeDisplay = calculateTimeDifference(wordle.createdAt).replace(' ', '');

    return (
        <div className="post-component">
            <div className="user-avatar-container">
                <img src={user.imageUri} alt="user" className="user-avatar" onClick={handleNameClick} />
            </div>
            <div className="post-content-wrapper">
                <div className="post-header">
                    <div className="user-info">
                        <span className="user-name" onClick={handleNameClick}>
                            {`${user.firstName} ${user.lastName}`}
                        </span>
                        <span className="user-visits">{wordle.streak > 0 ? `${wordle.streak}🔥` : ''}</span>
                        <span className="time">{timeDisplay}</span>
                    </div>
                </div>
                {/* Post content including message and Wordle grid */}
                <div className="post-content">
                    <div className="post-message">
                        <p>{wordle.message}</p>
                    </div>
                    <div className="wordle-grid">
                        {grid.map((row, rowIndex) => (
                            <div key={rowIndex} className="wordle-row">
                                {row.map((color, colIndex) => (
                                    <div key={colIndex} className={`wordle-cell ${color}`}></div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
                {/* Post footer with like button, like count, and delete button */}
                <div className="post-footer">
                    {liked ? (
                        <FavoriteIcon
                            className="heart-icon liked"
                            onClick={handleLikeClick}
                            style={{ cursor: loggedInUser.emailId ? 'pointer' : 'not-allowed' }}
                        />
                    ) : (
                        <FavoriteBorderIcon
                            className="heart-icon"
                            onClick={handleLikeClick}
                            style={{ cursor: loggedInUser.emailId ? 'pointer' : 'not-allowed' }}
                        />
                    )}
                    <span className="likes-count" onClick={handleLikesNumberClick}>
                        {likeCount}
                    </span>
                    {(loggedInUser.emailId === wordle.email || loggedInUser.isAdmin) && (
                        <DeleteIcon
                            className="delete-icon"
                            onClick={handleDeleteClick}
                            style={{ color: 'lightcoral', cursor: 'pointer' }}
                        />
                    )}
                </div>
            </div>
            {showLikesOverview && (
                <LikesOverview likers={wordle.likers} onClose={closeLikesOverview} />
            )}
            {showDeleteDialog && (
                <div className="delete-dialog">
                    <h2>Remove Post</h2> {/* Updated title */}
                    <p>Are you sure you want to delete this post?</p> {/* Updated content */}
                    <div className="delete-dialog-buttons">
                        <button className="cancel-button" onClick={handleDeleteCancel}>Cancel</button> {/* Updated button */}
                        <button className="delete-button" onClick={handleDeleteConfirm}>Delete</button> {/* Updated button */}
                    </div>
                </div>
            )}
        </div>
    );

};

export default PostOverview;

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Wordle from './pages/WordlePage';
import Feed from './pages/FeedPage';
import Profile from './pages/ProfilePage';
import ProfilePageExternalView from './pages/ProfilePageExternalView'; // Import the new component
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" exact element={<Feed />} />
        <Route path="/wordle" exact element={<Wordle />} />
        <Route path="/profile" exact element={<Profile />} />
        <Route path="/profile/:id" exact element={<ProfilePageExternalView />} /> {/* New route */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;

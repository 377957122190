import React, { useEffect, useState } from 'react';
import { backendDomain } from '../constants';
import './css/GuessDistributionOverview.css'; // Import CSS for styling

const GuessDistributionOverview = ({ user }) => {
    const [guessDistribution, setGuessDistribution] = useState([0, 0, 0, 0, 0, 0]);

    useEffect(() => {
        const loadGuessDistribution = async () => {
            try {
                const response = await fetch(`${backendDomain}/wordle/load-guess-distribution-for-user`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ emailId: user.emailId }),
                });

                if (response.ok) {
                    const data = await response.json();
                    if (data.status === 200) {
                        setGuessDistribution(data.guessDistribution);
                    } else {
                        console.error('Failed to load guess distribution');
                    }
                } else {
                    console.error('Network response was not ok');
                }
            } catch (error) {
                console.error('Error loading guess distribution:', error);
            }
        };

        loadGuessDistribution();
    }, [user.emailId]);

    const maxGuesses = Math.max(...guessDistribution);

    return (
        <div className="guess-distribution-overview-wrapper">
            <div className="guess-distribution-overview-container">
                <h3>Guess Distribution Overview</h3>
                <div className="guess-distribution-overview">
                    {guessDistribution.map((value, index) => (
                        <div
                            key={index}
                            className={`histogram-bar ${value === maxGuesses ? 'max-bar' : ''}`}
                        >
                            <span className="guess-number">{index + 1}</span>
                            <div className="bar">
                                <div
                                    className="bar-fill"
                                    style={{ width: maxGuesses ? `${(value / maxGuesses) * 100}%` : '0%' }}
                                >
                                    <span className="guess-count">{value}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default GuessDistributionOverview;

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { GoogleOAuthProvider } from '@react-oauth/google';
import './index.css';
import App from './App';
import { store } from './store';
import { googleClientId } from './constants';

ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={googleClientId}>
      <Provider store={store}>
        <App />
      </Provider>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
)